import { UppyFile } from '@uppy/core';
import _ from 'lodash';

export const isSuccessfulUpload = (arg: UppyFile): arg is CompleteUpload =>
  arg.progress?.uploadComplete === true &&
  _.has(arg as unknown, 'xhrUpload.headers');

export type CompleteUpload = {
  xhrUpload: {
    headers: Record<string, string>;
  };
} & UppyFile;

export function resourceAttachmentUploadParameters(
  resourceId: string,
  attachment: { url: string; id: string },
) {
  return {
    method: 'PUT',
    url: attachment.url,
    fields: {},
    headers: {
      'x-amz-meta-resourceid': resourceId,
      'x-amz-meta-attachmentid': attachment.id,
    },
  };
}

export function userSignatureUploadParameters(
  userId: string,
  attachment: { url: string; id: string },
) {
  return {
    method: 'PUT',
    url: attachment.url,
    fields: {},
    headers: {
      'x-amz-meta-userid': userId,
      'x-amz-meta-attachmentid': attachment.id,
    },
  };
}
