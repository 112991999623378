import getConfig from '@/config';
import { Configuration } from '@/config/types';
import { onBeforeMount, ref } from 'vue';

export function useConfig() {
  const config = ref<Configuration>();

  onBeforeMount(async () => {
    try {
      config.value = await getConfig();
    } catch (e) {
      console.warn('unable to load config');
    }
  });

  return config;
}
