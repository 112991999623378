import { getCurrentInstance } from 'vue';

export function useStore() {
  const root = getCurrentInstance()?.proxy;

  if (root === undefined) {
    throw Error('cant get root instance');
  }

  return root.$store;
}
