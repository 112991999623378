/* eslint-disable no-await-in-loop */
export const wait = async (ms = 3000) =>
  new Promise((resolve) => {
    console.log(`waiting ${ms} ms...`);
    setTimeout(resolve, ms);
  });

export async function poll<T>(
  fn: () => Promise<T>,
  fnCondition: (arg: T) => boolean,
  ms: number,
  maxPolls: number,
): Promise<T> {
  let result = await fn();
  let pollsCounter = 1;

  while (fnCondition(result) && pollsCounter <= maxPolls) {
    console.log(`Poll attempt ${pollsCounter}`);
    pollsCounter += 1;
    await wait(ms);
    result = await fn();
  }
  return result;
}
