import { Ref, ref, UnwrapRef } from 'vue';

export interface PromiseLazyResult<T, A> {
  perform: (arg?: A) => Promise<UnwrapRef<T>>;
  performing: Ref<boolean>;
  performed: Ref<boolean>;
  caught: Ref<boolean>;
  result: Ref<UnwrapRef<T>>;
}

export default function usePromiseLazy<T, A>(
  promiseFn: (arg?: A) => Promise<UnwrapRef<T>>,
  initial: T,
): PromiseLazyResult<T, A> {
  const performed = ref(false);
  const performing = ref(false);

  const caught = ref(false);
  const result = ref<T>(initial);

  const perform = async (arg?: A) => {
    try {
      performing.value = true;
      result.value = await promiseFn(arg);
      caught.value = false; // reset if successful
      return result.value;
    } catch (e) {
      console.warn(e);
      caught.value = true;
      return Promise.reject(e);
    } finally {
      performing.value = false;
      performed.value = true;
    }
  };

  return {
    perform,
    performing,
    performed,
    result,
    caught,
  };
}
