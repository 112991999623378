
import { RplDocumentLink } from '@dpc-sdp/ripple-document-link';
import RplIcon from '@dpc-sdp/ripple-icon';
import { formatBytes } from '@/helpers/file';
import Vue from 'vue';
import Confirm from '@/components/Confirm.vue';
import Uppy, { UppyFile } from '@uppy/core';
import { poll } from '@/api/poll';
import { useEmitWidgetEvent } from '@/components/composables/events/useWidgetEvents';
import { isSuccessfulUpload } from './resourceUppy';

type Data = {
  confirmCancel: boolean;
  scanning: boolean;
  infected: boolean;
  fileUploadError: boolean;
};
type Computed = {
  queued: boolean;
  uploading: boolean;
  complete: boolean;
  error: boolean;
  fileSizeString: string;
  progress: number;
  extension: string;
};
type Methods = {
  onCancelUpload: () => void;
  onCancelCancelUpload: () => void;
  onConfirmCancelUpload: () => void;
  onFileRemove: () => void;
  onInfectedRemove: () => void;
};
type Props = {
  upload: UppyFile & { error?: string };
  uppy: Uppy;
  attachmentIdKey: string; // Key to fetch attachmentId
  resourceIdKey: string; // Key to fetch resourceId
  fetchAttachmentApi: (
    entityId: string,
    attachmentId: string,
  ) => Promise<Record<string, unknown>>;
  deleteAttachmentApi: (
    entityId: string,
    attachmentId: string,
  ) => Promise<Record<string, unknown>>;
};
type SetupBinding = {
  attachmentCompleteEvent: (attachmentId: string) => void;
};

export default Vue.extend<Data, Methods, Computed, Props, SetupBinding>({
  name: 'Upload',
  components: { RplDocumentLink, RplIcon, Confirm },
  props: {
    upload: { type: Object, required: true },
    uppy: { type: Object, required: true },
    attachmentIdKey: { type: String, required: true },
    resourceIdKey: { type: String, required: true },
    fetchAttachmentApi: {
      type: Function,
      required: true,
      validator: (fn) => typeof fn === 'function',
    },
    deleteAttachmentApi: {
      type: Function,
      required: true,
      validator: (fn) => typeof fn === 'function',
    },
  },
  data() {
    return {
      confirmRemove: false,
      confirmCancel: false,
      scanning: false,
      infected: false,
      fileUploadError: false,
    };
  },
  setup() {
    const attachmentCompleteEvent = useEmitWidgetEvent('attachment-complete');
    return { attachmentCompleteEvent };
  },
  computed: {
    queued() {
      return !this.uploading && !this.complete && !this.error;
    },
    uploading() {
      return !this.complete && this.upload.progress?.uploadStarted !== null;
    },
    complete() {
      return this.upload.progress?.uploadComplete ?? false;
    },
    error() {
      return !!this.upload.error;
    },
    fileSizeString() {
      return formatBytes(this.upload.size);
    },
    progress() {
      return this.upload.progress?.percentage || 0;
    },
    extension() {
      return this.upload.name.substring(this.upload.name.lastIndexOf('.') + 1);
    },
  },
  methods: {
    onCancelUpload() {
      this.confirmCancel = true;
    },
    onCancelCancelUpload() {
      this.confirmCancel = false;
    },
    onConfirmCancelUpload() {
      // this happens during upload, add reason so we know to delete
      this.uppy.removeFile(this.upload.id, 'removed-by-user');
      this.confirmCancel = false;
    },
    onFileRemove() {
      // this happens prior to clicking upload, no reason
      this.uppy.removeFile(this.upload.id);
    },
    async onInfectedRemove() {
      if (!isSuccessfulUpload(this.upload)) {
        this.onFileRemove();
        return;
      }

      const attachmentId = this.upload?.xhrUpload.headers[this.attachmentIdKey]; // Dynamic attachmentId key
      const entityId = this.upload?.xhrUpload.headers[this.resourceIdKey];
      await this.deleteAttachmentApi(entityId, attachmentId);
      this.onFileRemove();
    },
  },
  watch: {
    async upload() {
      if (!isSuccessfulUpload(this.upload)) {
        return;
      }
      const attachmentId = this.upload?.xhrUpload.headers[this.attachmentIdKey]; // Dynamic attachmentId key
      const entityId = this.upload?.xhrUpload.headers[this.resourceIdKey];
      if (!attachmentId || !entityId) return;

      const fnCondition = (result: { status: string }) =>
        result.status !== 'UPLOADED';

      const statusFn = async () =>
        this.fetchAttachmentApi(entityId, attachmentId).then((data) => {
          const { status, virusScanStatus } = data;
          if (
            virusScanStatus === 'UNKNOWN' ||
            virusScanStatus === 'IN_PROGRESS'
          ) {
            this.scanning = true;
          }

          if (virusScanStatus === 'INFECTED' && status === 'DELETED') {
            this.scanning = false;
            this.infected = true;
          }

          if (status === 'UPLOADED') {
            this.scanning = false;
            this.attachmentCompleteEvent(attachmentId);

            setTimeout(() => {
              this.onFileRemove();
            }, 3000);
          }
          return data;
        });

      await poll(statusFn, fnCondition, 10000, 30);

      this.fetchAttachmentApi(entityId, attachmentId).then((data) => {
        const { status } = data;
        if (status !== 'UPLOADED') {
          this.fileUploadError = true;
        }
      });
    },
  },
});
