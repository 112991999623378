
import Vue from 'vue';
import Button from '@/components/ripple/Button.vue';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import { DragDrop } from '@uppy/vue';
import Uppy, { UppyFile, UppyOptions } from '@uppy/core';
import { formatBytes } from '@/helpers/file';

import UppyFileUpload from './UppyFileUpload.vue';

type Data = {
  files: UppyFile[];
  restrictionErrors: string[];
};
type UppyLocale = { strings: { dropHereOr: string; browse: string } };
type Computed = {
  disabled: boolean;
  locale: UppyLocale;
  dragDropProps: { locale: UppyLocale; note: string };
  note: string;
};
type Methods = {
  resetRestrictionError: () => void;
  onCancelClicked: () => void;
  onUploadClicked: () => void;
};

type Props = {
  title?: string;
  canUpload: boolean;
  disableUploadButton: boolean;
  uppy: Uppy & { opts: UppyOptions };
  attachmentIdKey: string;
  resourceIdKey: string;
  fetchAttachmentApi: (
    entityId: string,
    attachmentId: string,
  ) => Promise<Record<string, unknown>>;
  deleteAttachmentApi: (
    entityId: string,
    attachmentId: string,
  ) => Promise<Record<string, unknown>>;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'UppyUpload',
  components: {
    DragDrop,
    Button,
    UppyFileUpload,
    RplAlertBase,
  },
  methods: {
    resetRestrictionError() {
      this.restrictionErrors = [];
    },
    onCancelClicked() {
      this.uppy.cancelAll();
      this.restrictionErrors = [];
    },
    onUploadClicked() {
      this.uppy.upload();
      this.uppy.retryAll();
      this.uppy.resumeAll();
    },
  },
  computed: {
    disabled() {
      return (
        !this.canUpload || this.files.length === 0 || this.disableUploadButton
      );
    },
    dragDropProps() {
      return {
        locale: this.locale,
        note: this.note,
      };
    },
    note() {
      if (this.uppy.opts?.restrictions?.maxFileSize == null) {
        return '';
      }

      const maxSize = formatBytes(this.uppy.opts.restrictions.maxFileSize);
      return `Uploads are limited to ${maxSize}`;
    },
    locale() {
      const maxFiles = this.uppy.opts.restrictions?.maxNumberOfFiles ?? 1; // Default to 1 if null or undefined
      const fileString = maxFiles > 1 ? 'files' : 'file';
      let dropHereOr = `Drop ${fileString} here or click to browse`;

      if (
        this.uppy.opts.restrictions?.allowedFileTypes != null &&
        this.uppy.opts.restrictions.allowedFileTypes.length > 0
      ) {
        dropHereOr = `Drop ${this.allowedFileTypesString} ${fileString} here or click to browse`;
      }

      return {
        strings: {
          dropHereOr,
          browse: '',
        },
      };
    },
    allowedFileTypesString() {
      let allowedFileTypes = '';
      this.uppy.opts.restrictions.allowedFileTypes.forEach((allowed, index) => {
        allowedFileTypes += allowed;
        if (index === this.uppy.opts.restrictions.allowedFileTypes.length - 2) {
          allowedFileTypes += ', or ';
        } else if (
          index !==
          this.uppy.opts.restrictions.allowedFileTypes.length - 1
        ) {
          allowedFileTypes += ', ';
        }
      });
      return allowedFileTypes;
    },
  },
  props: {
    canUpload: { default: true },
    disableUploadButton: { default: false },
    uppy: { required: true },
    title: { type: String, default: '' },
    attachmentIdKey: { type: String, default: 'x-amz-meta-attachmentid' },
    resourceIdKey: { type: String, default: 'x-amz-meta-resourceid' },
    fetchAttachmentApi: {
      type: Function,
      required: true,
      validator: (fn: unknown) => typeof fn === 'function',
    },
    deleteAttachmentApi: {
      type: Function,
      required: true,
      validator: (fn: unknown) => typeof fn === 'function',
    },
  },
  data() {
    return {
      files: this.uppy.getFiles(),
      restrictionErrors: [],
    };
  },
  mounted() {
    this.uppy.on('*', () => {
      // need this to make sure the components refresh
      this.files = this.uppy.getFiles();
    });
    this.uppy.on('restriction-failed', (_: unknown, error: Error) => {
      this.restrictionErrors.push(error.message);
    });
  },
});
